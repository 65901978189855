<template>
	<button
		ref="wwSubmitButton"
		type="submit"
		:disabled="disabled"
		:class="[
			'relative transition-all duration-200 ease-in-out',
			disabled ? 'cursor-not-allowed' : ['cursor-pointer', hoverClasses],
			buttonWidth,
			buttonHeight,
			buttonClasses,
			bgColor,
			textClasses,
			focusClasses,
			borderRadius
		]"
		@click="emitSubmit"
	>
		<span
			:class="!showSuccessText ? 'opacity-100 scale-100' : 'opacity-0 scale-0'"
			class="absolute w-full transition-all duration-100 ease-in-out transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
		>
			{{ buttonText }}
		</span>
		<span
			:class="showSuccessText ? 'opacity-100 scale-100' : 'opacity-0 scale-0'"
			class="absolute w-full transition-all duration-100 ease-in-out transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
		>
			{{ successText }}
		</span>

		<span v-if="isLoading">
			<LoadingSpinner
				:class="[
					borderRadius,
					bgColor,
					'absolute z-10 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 w-full h-full p-2 opacity-75'
				]"
			/>
		</span>
	</button>
</template>

<script async>
import LoadingSpinner from '@/components/multiUse/LoadingSpinner.vue'

export default {
	components: {
		LoadingSpinner
	},
	props: {
		disabled: {
			type: Boolean,
			default: true
		},
		isLoading: {
			type: Boolean,
			default: false
		},
		buttonWidth: {
			type: String,
			default: 'w-full'
		},
		buttonHeight: {
			type: String,
			default: 'h-11'
		},
		buttonClasses: {
			type: String,
			default: 'px-3'
		},
		borderRadius: {
			type: String,
			default: 'rounded-md'
		},
		focusClasses: {
			type: String,
			default: 'focus:outline-none focus:ring focus:ring-offset-2 focus:ring-green-500 focus:ring-opacity-80'
		},
		buttonText: {
			type: String,
			default: 'Submit'
		},
		successText: {
			type: String,
			default: ''
		},
		successfulResponse: {
			type: Boolean,
			default: false
		},
		textClasses: {
			type: String,
			default: 'font-bold text-white uppercase'
		},
		hoverClasses: {
			type: String,
			default: 'xl:hover:bg-green-400'
		}
	},
	computed: {
		bgColor() {
			if (this.disabled) {
				return 'bg-gray-300'
			} else if (!this.disabled && this.isLoading) {
				return 'bg-green-400'
			} else {
				return 'bg-green-500'
			}
		},
		showSuccessText() {
			return this.successfulResponse && this.successText.length
		}
	},
	methods: {
		emitSubmit() {
			this.$emit('submit')
		}
	}
}
</script>
