var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{ref:"wwSubmitButton",class:[
		'relative transition-all duration-200 ease-in-out',
		_vm.disabled ? 'cursor-not-allowed' : ['cursor-pointer', _vm.hoverClasses],
		_vm.buttonWidth,
		_vm.buttonHeight,
		_vm.buttonClasses,
		_vm.bgColor,
		_vm.textClasses,
		_vm.focusClasses,
		_vm.borderRadius
	],attrs:{"type":"submit","disabled":_vm.disabled},on:{"click":_vm.emitSubmit}},[_c('span',{staticClass:"absolute w-full transition-all duration-100 ease-in-out transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2",class:!_vm.showSuccessText ? 'opacity-100 scale-100' : 'opacity-0 scale-0'},[_vm._v("\n\t\t"+_vm._s(_vm.buttonText)+"\n\t")]),_vm._v(" "),_c('span',{staticClass:"absolute w-full transition-all duration-100 ease-in-out transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2",class:_vm.showSuccessText ? 'opacity-100 scale-100' : 'opacity-0 scale-0'},[_vm._v("\n\t\t"+_vm._s(_vm.successText)+"\n\t")]),_vm._v(" "),(_vm.isLoading)?_c('span',[_c('LoadingSpinner',{class:[
				_vm.borderRadius,
				_vm.bgColor,
				'absolute z-10 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 w-full h-full p-2 opacity-75'
			]})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }