<template>
	<form
		class="mx-auto text-left md:max-w-sm selection:bg-green-500"
		@submit.prevent="subscribe"
	>
		<label
			for="newsletter-form"
			class="block pt-2 text-xl font-bold text-center text-black"
		>
			Join our newsletter!
		</label>
		<p class="pt-2 pb-8 text-sm font-normal text-center">
			Stay up to date with Where's Weed, Exclusive Offers, Cannabis news, Cannabis products, Information, and so much more.
		</p>
		<div class="grid grid-cols-1 gap-y-8">
			<WwTextInput
				id="name"
				v-model="name"
				:value="name"
				required
				placeholder="Your Name"
				input-class="w-full"
				autocomplete="name"
				:state="inputFieldState(name, TEXT)"
				label="Your Name"
				floating-label
				border-class="border-b-2"
				class="h-16"
				error-message="Please enter your name"
			/>
			<WwTextInput
				id="email"
				v-model="email"
				:value="email"
				required
				placeholder="your@email.com"
				input-class="w-full"
				autocomplete="email"
				:state="inputFieldState(email, EMAIL)"
				label="Email Address"
				floating-label
				border-class="border-b-2"
				class="h-16"
				error-message="Please enter a valid email address"
			/>
			<WwTextInput
				id="zip"
				v-model="zip"
				:value="zip"
				required
				placeholder="Zip Code"
				input-class="w-full"
				autocomplete="postal-code"
				:state="inputFieldState(zip, ZIP)"
				label="Zip Code"
				floating-label
				border-class="border-b-2"
				class="h-16"
				error-message="Please enter a valid zip code"
			/>
			<WwSubmitButton
				:disabled="!formIsValid"
				:is-loading="isLoading"
				success-text="Subscribed!"
				:successful-response="successfulResponse"
				button-text="SIGN UP"
				button-width="w-full"
				text-classes="font-bold text-white normal-case"
			/>
		</div>
	</form>
</template>

<script async>
import { mapMutations } from 'vuex'

import WwSubmitButton from '@/components/UI/buttons/WwSubmitButton.vue'
import WwTextInput from '@/components/UI/WwTextInput.vue'
import { NEWSLETTER_SIGNUP_MODAL } from '@/constants/modal/names.js'
import INPUT_STATES from '@/constants/text-inputs/states.js'
import { EMAIL, TEXT, ZIP } from '@/constants/text-inputs/types.js'
import { ERROR } from '@/constants/toast/type.js'
import { NewsletterSignup } from '@/gql/mutations/marketing.gql'
import { componentLevelGQLErrors } from '@/utils/error-handling.js'
import { inputFieldState } from '@/validators/text-inputs.js'

export default {
	components: {
		WwTextInput,
		WwSubmitButton
	},
	data() {
		return {
			NEWSLETTER_SIGNUP_MODAL,
			inputFieldState,
			EMAIL,
			TEXT,
			ZIP,
			name: '',
			email: '',
			zip: '',
			isLoading: false,
			successfulResponse: false
		}
	},
	computed: {
		formIsValid() {
			return this.name?.length && inputFieldState(this.zip, ZIP) === INPUT_STATES.SUCCESS && inputFieldState(this.email, EMAIL) === INPUT_STATES.SUCCESS
		}
	},
	methods: {
		...mapMutations('modal', [ 'closeModal' ]),
		...mapMutations('toast', [ 'showToast' ]),
		async subscribe() {
			try {
				const response = await this.$apollo.mutate({
					mutation: NewsletterSignup,
					variables: {
						name: this.name,
						email: this.email,
						zip: this.zip
					}
				})
				this.successfulResponse = !!response.data?.newsletterSignup
			} catch (error) {
				componentLevelGQLErrors(error)
				this.showToast({
					primaryText: error.message,
					type: ERROR
				})
			} finally {
				this.isLoading = false
				setTimeout(() => {
					if (this.successfulResponse) this.clearInputs()
					this.successfulResponse = false
				}, 1000)
			}
		},
		clearInputs() {
			this.name = ''
			this.email = ''
			this.zip = ''
			this.closeModal()
		}
	}
}
</script>
